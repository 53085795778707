<template>
  <div>
    <v-tabs v-model="tab_details">
      <v-tab dense v-for="tab of tabs" :key="tab.id">
       {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      style="font-size: 0.9rem;flex-grow: 1;"
      class="py-1"
      v-model="tab_details"
    >
      <v-tab-item :value="0">
        <v-card>
          <h1 class="ms-4">PRESUPUESTOS</h1>
          <PresupuestosPendientes></PresupuestosPendientes>
          <PresupuestosEnviados></PresupuestosEnviados>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="1">
        <Evolutivo></Evolutivo>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

export default {
  props: {
    ind: {
      type: Number,
      default: 0,
    },
  },
  components: {
    PresupuestosPendientes: () => import("../components/PresupuestosPendientes.vue"),
    PresupuestosEnviados: () => import("../components/PresupuestosEnviados.vue"),
    Evolutivo: () => import("../components/Evolutivo.vue"),
  },
  data(){
    return {
      value: 0,
      tab_details: 0,
      tabs:[
        { id: 1, name: 'PRESUPUESTOS' },
        { id: 2, name: 'PRUEBAS BODA' }
      ]
    }
  },
  mounted(){
    if(this.ind != undefined) this.$set(this,'tab_details', this.ind)
  }
};
</script>